<template>
	<div ref="form" class="flex flex-col flex-grow justify-center">
		<FormKit
			v-model="formData"
			type="form"
			:actions="true"
			submit-label="Test Submit"
			:no-empty-params="true"
			:submit-attrs="{
				'sections-schema': {
					prefix: loadingElement('loginForm'),
				},
				outerClass: {
					'col-span-12': true,
					'sm:col-span-12': true,
					'lg:col-span-12': true,
					'xl:col-span-12': true,
					flex: true,
					'flex-row': true,
					'w-full': true,
					'justify-end': true,
				},
			}"
			@submit="testSubmission"
		>
			<div class="grid grid-cols-12 gap-x-2 my-4">
				<FormKitSchema :schema="formSchema" :data="formData" />
			</div>
		</FormKit>
		<hr class="my-3" />
		<h2 class="text-lg">Live form data</h2>
		<pre class="whitespace-pre-wrap">{{ cleanFormData(formData) }}</pre>
	</div>
</template>

<script lang="ts" setup>
	import { FormKitSchema } from '@formkit/vue';
	import { useRoute } from 'vue-router';
	import { ref } from 'vue';
	import { FormKitSchemaNode } from '@formkit/core';
	import { useResizeObserver } from '@vueuse/core';
	import { loadingElement } from '@modules/form/utils/elements';
	import { cleanFormData } from '../utils/helpers';

	const route = useRoute();
	const formSchema = ref<FormKitSchemaNode[]>(JSON.parse(atob(route.params.form_schema as string)));
	const formData = ref({});
	const form = ref(null);

	const testSubmission = (formData: Record<string, unknown>) => {
		console.dir(cleanFormData(formData));
	};

	useResizeObserver(form, (entries) => {
		const entry = entries[0];
		const { height } = entry.contentRect;
		window.parent.postMessage(
			{
				height: height,
			},
			'*',
		);
	});
</script>
